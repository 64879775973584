export const dateArr = [
  {
    title: '日期',
    prop: 'date',
    width: 130,
    align: 'left',
    label: '日期',
    scopedSlots: { customRender: 'date' },
    fixed: true
  }
]

export const allDimesion = [
  {
    key: 'publisher_id',
    prop: 'publisherName',
    isWrap: false,
    label: '供应商',
    checked: false,
    width: 120
  },
  {
    key: 'place_id',
    prop: 'placeName',
    isWrap: false,
    label: '广告位',
    checked: false,
    width: 180
  },
  {
    key: 'position_id',
    prop: 'positionName',
    isWrap: false,
    label: '广告样式',
    checked: false,
    width: 100
  },
  {
    key: 'os',
    prop: 'os',
    isWrap: false,
    label: '操作系统',
    checked: false,
    width: 80
  },
  {
    key: 'country_code',
    prop: 'countryName',
    isWrap: false,
    label: '地区',
    checked: false,
    width: 150
  },
  {
    key: 'bundle',
    prop: 'bundle',
    isWrap: false,
    label: '应用包名',
    checked: false,
    width: 180
  },
  {
    key: 'traffic_place_id',
    isWrap: false,
    label: '流量广告位ID',
    checked: false,
    prop: 'trafficPlaceId',
    width: 180
  },
  {
    key: 'task',
    prop: 'task',
    isWrap: false,
    label: '任务',
    checked: false,
    width: 180
  }

]
